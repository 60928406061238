define("ember-power-select/templates/components/power-select/search-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KS9Sx55k",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\"],[11,\"class\",\"ember-power-select-options\"],[11,\"role\",\"listbox\"],[9],[0,\"\\n  \"],[7,\"li\"],[11,\"class\",\"ember-power-select-option ember-power-select-option--search-message\"],[11,\"role\",\"option\"],[9],[0,\"\\n    \"],[1,[21,\"searchMessage\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/search-message.hbs"
    }
  });
  _exports.default = _default;
});