define('ember-tooltips/utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dispatchAction = dispatchAction;
  var onComponentTarget = exports.onComponentTarget = Ember.computed(function () {

    var targetView = void 0;

    if (this.get('_shouldTargetGrandparentView')) {

      /* The parentView is the lazy-render-wrapper
      and we want to ignore that tagless component
      */

      targetView = this.get('parentView.parentView');
    } else {
      targetView = this.get('parentView');
    }

    if (!targetView) {
      (true && Ember.warn('No targetView found'));


      return null;
    } else if (!targetView.get('elementId')) {
      (true && Ember.warn('No targetView.elementId'));


      return null;
    } else {
      return '#' + targetView.get('elementId');
    }
  });

  function dispatchAction(context, actionName) {
    var action = context.get(actionName);

    if (typeof action === 'string') {
      Ember.deprecate('Use of \'' + actionName + '\' with an action name is deprecated. Please pass a closure action instead of \'' + action + '\'', false, {
        id: 'ember-tooltips-send-action-use',
        until: '3.0.0'
      });
      context.sendAction(actionName, context); // eslint-disable-line ember/closure-actions
    } else if (action) {
      action(context);
    }
  }
});