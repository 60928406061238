define("ember-api-actions/index", ["exports", "ember-api-actions/utils/collection-action", "ember-api-actions/utils/member-action", "ember-api-actions/utils/serialize-and-push"], function (_exports, _collectionAction, _memberAction, _serializeAndPush) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.classOp = void 0;
  Object.defineProperty(_exports, "collectionAction", {
    enumerable: true,
    get: function get() {
      return _collectionAction.default;
    }
  });
  _exports.instanceOp = void 0;
  Object.defineProperty(_exports, "memberAction", {
    enumerable: true,
    get: function get() {
      return _memberAction.default;
    }
  });
  Object.defineProperty(_exports, "serializeAndPush", {
    enumerable: true,
    get: function get() {
      return _serializeAndPush.default;
    }
  });
  var classOp = _exports.classOp = _collectionAction.default;
  var instanceOp = _exports.instanceOp = _memberAction.default;
});