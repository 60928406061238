define('ember-drag-drop-polyfill/initializers/ember-drag-drop-polyfill', ['exports', 'ember-get-config', 'mobile-drag-drop'], function (exports, _emberGetConfig, _mobileDragDrop) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{

    var options = _emberGetConfig.default['ember-drag-drop-polyfill'] || {};
    (0, _mobileDragDrop.polyfill)(options);
  }

  exports.default = {
    name: 'ember-drag-drop-polyfill',
    initialize: initialize
  };
});